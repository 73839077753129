<template>
<div class="form-group row">

    <div class="col-md-3">
        <label for="tax" class="col-form-label control-label"> {{ getMasterLabelByType(labelid,MASTER_TAXES) }}</label>
    </div>
    <div class="col-md-6">
        <input type="number" step="0.01" class="form-control form-cascade-control input-small" v-model="producttax" required>
        <span class="invalid-feedback" role="alert">
            <strong></strong>
        </span>
    </div>

</div>
</template>
<script>
import Constants from '../../components/utilities/Constants'
import { mapGetters } from 'vuex'
export default {
    mixins:[Constants],
    props:['labelid'],
    computed:{
        producttax:{
            get(){
                if(this.edititem!=null && this.edititem.tax!=null){
                    let selectedtax = this.edititem.tax.find(block=>block.labelid==this.labelid)
                    
                    if(typeof selectedtax!=='undefined'){
                        return selectedtax.value1
                    }
                } else {
                    let tax = this.$parent.form.tax
                    if(tax!=null && Object.prototype.toString.call(tax) === '[object Array]'){
                        let selectedtax = tax.find(block=>block.labelid==this.labelid)
                    
                        if(typeof selectedtax!=='undefined'){
                            return selectedtax.value1
                        }
                    }
                }
                return 0;
            },
            set(value){
                if(this.edititem!=null){
                    let obj = {productid:this.edititem.id,labelid:this.labelid,value1:value}
                    this.$store.commit('updateappendnewtax',obj);
                } else {
                    
                    let tax = this.$parent.form.tax
                    if(tax!=null && Object.prototype.toString.call(tax) === '[object Array]') {
                        console.log(tax)
                        let selectedtax = tax.find(block=>block.labelid==this.labelid)
                    
                        if(typeof selectedtax!=='undefined'){
                            return selectedtax.value1 = value
                        } else {
                            let obj = {productid:0,labelid:this.labelid,value1:value}
                            tax.push(obj)
                        }
                    } else {
                        let obj = {productid:0,labelid:this.labelid,value1:value}
                        this.$store.commit('updateappendnewtax',obj);
                    }
                    this.$parent.form.tax = tax
                }
            }
        },
        ...mapGetters([
			'edititem'
		]),
    },
}
</script>