<template>
	<div>
		<div class="col-md-12">
			<div class="row" style="margin-top:20px">
				<div class="col-md-6">
					<h3 class="page-header">
						<i class="fa fa-linode animated bounceInDown show-info"></i>
						Add New Model
					</h3>
				</div>
				<div class="col-md-6 ">
					<div class="row">
						<!-- <div class="col-md-10">
							<div class="row" style="padding-top:18px;justify-content:flex-end;">
								<div class="col-md-2" style="margin-right:25px;">
									<button @click="refresh()" class="btn btn-primary"><i class="fa fa-refresh"></i>
										Refresh</button>
								</div>
							</div>
						</div> -->
					</div>
				</div>
			</div>
		</div>
		<form @submit.prevent="onSubmit" @keydown="form.errors.clear($event.target.name)"
			class="p-8 lg:w-1/2 md:mx-auto">
			<div class="panel panel-cascade">
				<div class="panel-body">
					<div class="form-horizontal cascde-forms">
						<div class="col-md-12">

							<div class="form-group row">
								<div class="col-md-3">
									<label for="subcategory" class="col-form-label control-label">Select
										Category</label>
								</div>
								<div class="col-md-6">
									<select name="Parent Id" id="subcategory"
										class="form-control form-cascade-control input-small" v-model="form.parent_id">
										<!-- <option selected>----Select-----</option> -->
										<option v-for="item in filtercategories" v-bind:value="item.id" :key="item.id">
											{{ item.name }}</option>
									</select>
									<span class="invalid-feedback" role="alert">
										<strong></strong>
									</span>
								</div>
							</div>
							<div class="form-group row">
								<div class="col-md-3">
									<label for="subcategory" class="col-form-label control-label">Select Product</label>
								</div>
								<div class="col-md-6">
									<select name="Parent Id" id="subcategory"
										class="form-control form-cascade-control input-small"
										v-model="form.category_id">
										<!-- <option selected>----Select-----</option> -->
										<option v-for="item in filters" v-bind:value="item.id" :key="item.id">{{
											item.name
											}}
										</option>
									</select>
									<span class="invalid-feedback" role="alert">
										<strong></strong>
									</span>
								</div>
							</div>
							<div class="form-group row">
								<div class="col-md-3">
									<label for="subcategory" class="col-form-label control-label">Company</label>
								</div>
								<div class="col-md-6">
									<select v-model="brandid" class="form-control form-cascade-control input-small">
										<template v-if="brands != null">
											<option v-for="filter in brands.predefinedvalues" :key="filter.id"
												:value="filter.id">{{ filter.value }}</option>
										</template>
									</select>
									<span class="invalid-feedback" role="alert">
										<strong></strong>
									</span>
								</div>
							</div>
							<div class="form-group row">
								<div class="col-md-3">
									<label for="firstname" class="col-form-label control-label">Product Name<span
											style="color: rgba(255, 0, 0, 0.67)">*</span></label>
								</div>
								<div class="col-md-6">
									<input type="text" class="form-control form-cascade-control input-small"
										name="firstname" v-model="form.displayproduct" required>
									<span class="invalid-feedback" role="alert">
										<strong></strong>
									</span>
								</div>
							</div>
							<div class="form-group row">
								<div class="col-md-3">
									<label for="firstname" class="col-form-label control-label">Model Name<span
											style="color: rgba(255, 0, 0, 0.67)">*</span></label>
								</div>
								<div class="col-md-6">
									<input type="text" class="form-control form-cascade-control input-small"
										name="firstname" v-model="form.name" required>
									<span class="invalid-feedback" role="alert">
										<strong></strong>
									</span>
								</div>
							</div>
							<div class="form-group row">
								<div class="col-md-3">
									<label  class="col-form-label control-label">Model No.<span
											style="color: rgba(255, 0, 0, 0.67)">*</span></label>
								</div>
								<div class="col-md-6">
									<input type="text" v-model="form.modelno"
										class="form-control form-cascade-control input-small">
									<span class="invalid-feedback" role="alert">
										<strong></strong>
									</span>
								</div>
							</div>
							<!-- <div class="form-group row">
								<div class="col-md-3">
									<label  class="col-form-label control-label">Colour<span
											style="color: rgba(255, 0, 0, 0.67)">*</span></label>
								</div>
								<div class="col-md-6">
									<input type="text" v-model="colour"
										class="form-control form-cascade-control input-small">
									<span class="invalid-feedback" role="alert">
										<strong></strong>
									</span>
								</div>
							</div> -->
							<!-- <div class="form-group row">
								<div class="col-md-3">
									<label  class="col-form-label control-label">Purchase Price</label>
								</div>
								<div class="col-md-6">
									<input type="number" v-model="form.cp"
										class="form-control form-cascade-control input-small">
								</div>
							</div> -->
							<div class="form-group row">
								<div class="col-md-3">
									<label  class="col-form-label control-label">Display name on
										invoice</label>
								</div>
								<div class="col-md-6">
									<input type="text" v-model="form.nameoninvoice"
										class="form-control form-cascade-control input-small">
								</div>
							</div>
							<div class="form-group row">
								<div class="col-md-3">
									<label  class="col-form-label control-label">Retail Price (%)</label>
								</div>
								<div class="col-md-6">
									<input type="number" v-model="form.retail"
										class="form-control form-cascade-control input-small">
								</div>
							</div>
							<div class="form-group row">
								<div class="col-md-3">
									<label  class="col-form-label control-label">Wholesale Price (%)</label>
								</div>
								<div class="col-md-6">
									<input type="number" v-model="form.wholesale"
										class="form-control form-cascade-control input-small">
								</div>
							</div>
							<div class="form-group row">
								<div class="col-md-3">
									<label  class="col-form-label control-label">Emi Cost</label>
								</div>
								<div class="col-md-6">
									<input type="number" v-model="emicost"
										class="form-control form-cascade-control input-small">
								</div>
							</div>
							<div class="form-group row">
								<div class="col-md-3">
									<label  class="col-form-label control-label">HSN Code</label>
								</div>
								<div class="col-md-6">
									<input type="number" v-model="form.hsn"
										class="form-control form-cascade-control input-small">
								</div>
							</div>
							<!-- <div class="form-group row">
								<div class="col-md-3">
									<label for="gender" class="col-form-label control-label">Long Description</label>
								</div>
								<div class="col-md-6">
									<textarea class="form-control form-cascade-control input-small" name="" id="" cols="30" rows="3" v-model="form.longdescription"></textarea>
									<span class="invalid-feedback" role="alert">
										<strong></strong>
									</span>
								</div>
							</div> -->


							<div class="" v-for="(item) in getmastertype(MASTER_TAXES)" v-bind:key="item.id">

								<product-tax :labelid="item.labelid"></product-tax>
							</div>
							<div class="form-group row">
								<div class="col-md-3">
									<label  class="col-form-label control-label">Additional Schemes</label>
								</div>
								<div class="col-md-6">
									<textarea class="form-control form-cascade-control input-small" name="" id=""
										cols="30" rows="3" v-model="form.description"></textarea>
									<span class="invalid-feedback" role="alert">
										<strong></strong>
									</span>
								</div>
							</div>
							<div class="form-group row">
								<div class="col-md-3">
									<label  class="col-form-label control-label">Short Description</label>
								</div>
								<div class="col-md-6">
									<textarea class="form-control form-cascade-control input-small" name="" id=""
										cols="30" rows="3" v-model="form.shortdescription"></textarea>
									<span class="invalid-feedback" role="alert">
										<strong></strong>
									</span>
								</div>
							</div>
							<!-- Logn description changes to description for app -->
							<div class="form-group row">
								<div class="col-md-3">
									<label  class="col-form-label control-label">Long Description</label>
								</div>
								<div class="col-md-6">
									<textarea class="form-control form-cascade-control input-small" name="" id=""
										cols="30" rows="3" v-model="form.description"></textarea>
									<span class="invalid-feedback" role="alert">
										<strong></strong>
									</span>
								</div>
							</div>

							<div class="form-group row">
								<div class="col-md-3">
									<label  class="col-form-label control-label">Service Center
										Details</label>
								</div>
								<div class="col-md-6">
									<textarea class="form-control form-cascade-control input-small" cols="30" rows="3"
										v-model="form.servicecenter"></textarea>
									<span class="invalid-feedback" role="alert">
										<strong></strong>
									</span>
								</div>
							</div>

							<div class="col-md-12  text-center">
								<div class="row">
									<div class="col-md-4"></div>
									<div class="col-md-2">
										<button class="btn btn-primary ">Submit</button>
									</div>
									<div class="col-md-2">
										<button class="btn btn-danger" @click="cancel">Cancel</button>
									</div>
								</div>

								<!-- <div class="col-md-3">
									<input type="text" class="form-control" v-model="colour"/>
									<button class="btn btn-info" @click="test()">Test</button>
								</div> -->
							</div>
						</div>
					</div>
				</div>
			</div>

		</form>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import ProductTax from './ProductTax.vue'
import Constants from '../../components/utilities/Constants'
import axios from 'axios'
export default {
	mixins: [Constants],
	components: {
		ProductTax
	},
	data() {
		return {
			category: null,
			form: new window.Form({
				id: 0, name: '', category_id: 0, sp: 0, servicecenter: '', retail: 0, nameoninvoice: '',
				description: '', attributes: null, emi: '', tax: '', cp: 0, modelno: '', shortdescription: '',
				longdescription: '', displayproduct: '', wholesale: 0, emi_cost: '', new_attribute: '', hsn: ''

			}),
			brandid: 0,
			emicost: '',
			colour: ''
		}
	},
	mounted() {
		this.initialize()
		this.$store.dispatch('fetchcategories')
		this.$store.dispatch('fetchattributes')
	},

	computed: {
		...mapGetters([
			'edititem', 'categories', 'newtax', 'attributes', 'selectedstoreid'
		]),

		filters() {
			return this.categories.filter(item => item.parent_id != 0 && item.parent_id != null)
			// return this.categories.filter(block => block.parent_id == this.form.parent_id)
		},
		brands() {
			let attribute = this.attributes.find(block => block.id == 2)
			return attribute
		},
		filtercategories() {
			return this.categories.filter(block => block.parent_id == 0)
		}
	},

	methods: {
		initialize() {
			console.log("inside initialize")
			if (this.edititem != null) {
				this.form.parent_id = this.edititem.category.parent_id
				this.form.id = this.edititem.id
				this.form.name = this.edititem.name
				this.form.category_id = this.edititem.category_id
				this.form.sp = this.edititem.sp
				this.form.retail = this.edititem.retail
				this.form.nameoninvoice = this.edititem.nameoninvoice
				this.form.cp = this.edititem.cp
				this.form.modelno = this.edititem.modelno
				this.form.description = this.edititem.description
				this.form.shortdescription = this.edititem.shortdescription
				this.form.longdescription = this.edititem.longdescription
				this.form.emi = this.edititem.emi
				this.form.displayproduct = this.edititem.displayproduct
				this.form.servicecenter = this.edititem.servicecenter
				this.form.wholesale = this.edititem.wholesale
				this.form.hsn = this.edititem.hsn
				// console.log("edititem",this.edititem)
				// console.log("emi cost",JSON.parse(this.edititem?.emi_cost))
				let emicost = JSON.parse(this.edititem?.emi_cost)
				this.emicost = emicost?.emi_cost
				// this.colour = JSON.stringify(this.edititem?.attributejson).colour
				this.$store.commit('assignnewtax', this.edititem.tax)
				if (this.edititem.attribute != null) {
					let attribute = this.edititem.attribute.find(block => block.attribute_id == 2)
					if (typeof attribute !== 'undefined') {
						this.brandid = Number.parseInt(attribute.attribute_value_ids)
						console.log(this.brandid)
					}
				}

			} else {
				this.$store.commit('assignedititem',[])
				this.$store.commit('assignnewtax', [])
			}
		},
		onSubmit: function () {
			this.$notify({
				text: 'Submitted',
				type: 'warning',
				duration: '80000',
				speed: '800',
				closeOnClick: false
			})
			this.$store.commit('assignloadingstatus', 1)
			let attribute = { attribute_id: 2, ispredefined: 1, value: this.brandid }
			this.form.attributes = [];
			this.form.attributes.push(attribute);
			this.form.tax = this.newtax;
			this.form.storeid = this.selectedstoreid
			this.form.new_attribute = { colour: this.colour?.replace(/ +/g, "") }
			this.form.emi_cost = { emi_cost: this.emicost }
			console.log(this.form)

			this.form.submit(this, 'post', 'api/product/createupdate2')
				.then(response => this.onSuccess(response))
				.catch(error => this.errorShow(error));
		},
		onSuccess: function (data) {
			if (data.success == true) {
				this.$store.commit("createupdateallproducts", data.data)
				this.$store.commit('assignviewno', 1)
				this.$notify({
					text: 'Registered Successfully',
					type: 'success',
					duration: '80000',
					speed: '800',
					closeOnClick: false
				})
				this.$store.commit('assignloadingstatus', 0)
			} else {
				this.$notify({
					text: 'Already Exist',
					type: 'error',
					duration: '80000',
					speed: '800',
					closeOnClick: false
				})
				this.$store.commit('assignloadingstatus', 0)
			}
			this.clear()

		},
		clear() {
			this.emicost = ''
			this.colour = ''
			this.form = []
			this.brandid = 0
		},

		cancel() {
			this.$parent.isedit = !this.$parent.isedit;
			this.$store.commit('assignedititem', null)
			this.$store.commit('assignviewno', 1)
		},
		errorShow() {
			this.$store.commit('assignloadingstatus', 0)
			alert("Something went wrong, please try after sometime.")
		},
		getemicost(item) {
			let json = JSON.parse(item.emi_cost).emi_cost
			// this.emicost = json.emi_cost
			console.log(json)
		},
		// getEditCategory(){
		// 	console.log(this.categories)
		// },
		test() {
			let param = { new_attribute: { colour: this.colour } }
			console.log(param)
			axios.post('api/test/data', param)
				.then((response) => this.processTestResponse(response.data))
				.catch((error) => console.log(error))
		},
		processTestResponse(data) {
			console.log(data)
		},

	}
}

</script>
